:root {
  --blue: #0098DA;
  --darkblue: #028eca;
  --white: #ffffff;
}

/* CSS */
.blue-btn {
  font-family: 'Poppins', sans-serif;
  display: grid;
  place-items: center;
  text-transform: capitalize;
  /* background-color: var(--blue); */
  border: 1px solid transparent;
  border-radius: .75rem;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  padding: 12px 25px;
  font-size: 16px;
  letter-spacing: 2px;
  font-weight: 700;
  user-select: none;
  -webkit-user-select: none;
  width: auto;
}

.plan-btn {
  font-family: 'Poppins', sans-serif;
  ;
  display: grid;
  place-items: center;
  text-transform: capitalize;
  /* background-color: var(--blue); */
  border: 1px solid transparent;
  border-radius: .75rem;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  padding: 10px 15px;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 700;
  user-select: none;
  -webkit-user-select: none;
  width: auto;
}

.blue-btn:hover,
.plan-btn {
  background-color: var(--darkblue);
}

.blue-btn:focus,
.plan-btn {
  box-shadow: none;
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.plan-btn {
  margin: 10px 0px;
}


@media (max-width:576px){
  .blue-btn {
    padding: 10px 18px;
    font-size: 12px;
  }
}