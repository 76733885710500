.product-detail-tab-img img {
    width: 100px;
}
.product-detail-tab-card {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 30px;
    width: 280px;
    height: 200px;
    border-radius: 20px;
    box-shadow: var(--box-shadow);
    background-color: white;
}
.stem-tree {
    
    background-image: url(../../../assets/graphic/bg-graphic/stem-tree-large.svg);
}

@media (width < 576px) {
    .stem-tree{
        background-image: unset;
        position: unset;
        display: flex;
        justify-content: space-evenly;
    }
}