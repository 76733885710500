.tab-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin: auto;
    justify-content: center;
    align-items: center;
}

@media (width < 576px) {
    .tab-container {
        grid-template-columns: 1fr;
    }
}

.top-img-buzz {
    cursor: pointer;
    object-fit: cover;
    height: 288px;
    width: 452px;
    border-radius: 20px;
}


.blog-img-div {
    width: 100%;
    height: 256px;
}

@media(width < 576px) {
    .blog-img-div {
        height: 180px;
    }
}

.blog-img-div img {
    cursor: pointer;
    object-fit: cover;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    width: 100%;
    height: 100%;
}

.img-footer {
    margin: 0;
    padding: 10px 20px;
    background-color: #eaeaeb;
    display: flex;
    list-style: none;
    justify-content: space-between;
}

.blog-text-section {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.blog-text-section-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0px;
}

.blog-img-div {
    width: 100%;
    height: 256px;
}

@media(width < 576px) {
    .blog-img-div {
        height: 180px;
    }
}

.blog-img-div img {
    cursor: pointer;
    object-fit: cover;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    width: 100%;
    height: 100%;
}
.blog-description {
    margin: 0;
}

.stock-market-news-section {
    /* flex-direction: row; */
    width: 75%;
    margin: auto;
    background-color: white;
    padding: 20px;
    border-radius: 20px;
    box-shadow: var(--box-shadow);
}

.buzz-tab-card {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 20px;
    width: 300px;
    height: 100%;
    border-radius: 20px;
    box-shadow: var(--box-shadow);
    background-color: white;
}

.buzz-card-img {
    width: fit-content;
    display: flex;
    margin: auto;
}

.buzz-card-img img {
    width: 150px;
    border-radius: 20px;
}