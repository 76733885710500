.contact-banner {
    background-image: url(../../assets/img/enqueryDivBanner.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% 100%;
    height: 280px;
    width: 100%;
}

.contact-banner-left {
    width: 55%;
}

.contact-main-banner-div {
    display: flex;
    height: 100%;
}

.contact-inner-banner {
    display: flex;
    width: 75%;
    margin: auto;
    align-items: center;

}

.about-banner-right {
    display: flex;
    justify-content: center;
}

.banner-img-sec {
    width: 240px;
    /* margin-top: 30px; */
}

.banner-img-sec img {
    width: 100%;
}


body {
    font-family: Arial, sans-serif;
    background-color: #f0f0f0;
}

.container {
    max-width: 1200px;
}

.contact-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 10px;
    margin: 40px auto;
    width: 75%;
}

.contact-card {
    /* width: 320px; */
    background-color: #fff;
    padding: 20px;
    box-shadow: var(--box-shadow);
    border-radius: 10px;
}


.contact-info {
    display: grid;
    /* flex-wrap: wrap; */
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    width: 100%;
    flex-wrap: wrap;
    /* justify-content: space-between; */
}

.contact-address {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border-radius: 10px;
    background-color: #fff;
    padding: 32px;
    box-shadow: var(--box-shadow);
    width: 100%;
    margin-top: 30px;
}

.contact-item {
    display: flex;
    align-items: flex-start;
}

.contact-icon {
    width: 24px;
    margin-right: 10px;
}

.contact-label {
    font-weight: bold;
    margin-bottom: 5px;
}

.contact-link {
    text-decoration: none;
    color: gray;
}

.social-links {
    display: flex;
}

.social-icon {
    width: 20px;
    margin-right: 10px;
}

.address-section {
    margin-bottom: 20px;
    padding-right: 42px;
}

.address-title {
    font-weight: bold;
}

.address-details {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.address-icon {
    width: 24px;
    margin-right: 10px;
}

.map-frame {
    width: 100%;
    height: 300px;
    border: none;
}

.map {
    padding: 15px 0px;
}

.write-title {
    font-weight: bold;
}

.form-field {
    margin-top: 20px;
}

.form-field-section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    margin-top: 16px;
}

.form-select {
    color: gray;
    font-size: 16px;
    padding: 10px 22px;
    background-color: rgb(243 243 246/1);
    border: 1px solid #ccc;
}

.form-input {
    width: 100%;
    height: 48px;
    padding: 22px;
    border: 1px solid #ccc;
    background-color: rgb(243 243 246/1);
    border-radius: 8px;
    margin-top: 8px;
}

.form-textarea {
    width: 100%;
    height: 128px;
    padding: 10px 22px;
    border: 1px solid #ccc;
    background-color: rgb(243 243 246/1);
    border-radius: 8px;
    margin-top: 8px;
}


.btn_div {
    margin-top: 10px;
}

@media (max-width:968px) {
    .form-field-section {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        margin-top: 16px;
    }
}

@media (max-width:468px) {
    .form-field-section {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
        margin-top: 16px;
    }
}

@media(width<576px) {
    .contact-banner-left {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
    }
    .contact-address{
        padding: 20px;
    }
    .contact-inner-banner {
        margin-top: 30px;
        width: 80%;
        gap: 30px;
        flex-direction: column;
    }

    .address-section {
        padding-right: 0px;
    }

    .contact-banner {
        height: 100%;
    }
    .contact-section{
        margin: 20px;
        width: auto;
    }

    .contact-info,
    .contact-address {
        grid-template-columns: repeat(1, 1fr);
    }

    .banner-img-sec {
        width: 180px;
    }

    .form-input {
        height: 40px;
        padding: 15px;
    }
}