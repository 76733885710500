.enquire-div-container {
    position: relative;
    gap: 30px;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    height: 50vh;
    border-radius: 20px;
}

.faq-bg-img {
    position: absolute;
    bottom: 0px;
    left: 2%;
    height: fit-content;
    z-index: -1;
    }
    @media (width < 576px) {
        .faq-bg-img{
            max-width: 300px;
            width: 300px;
           
        }
        .enquire-div-container{
            height: 40vh;
        }   
    }
    .faq-bg-img img {
        width: 400px;
    }
    @media (width < 576px) {
        .faq-bg-img img{
            width: 100%;
        }
    }
