.reciept-table-container {
    border-top: 5px solid black;
    border-bottom: 5px solid black;
    padding-top: 50px !important;
    z-index: -10;

}

.section-one img,
.section-two img {
    width: 500px;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    opacity: 0.3;
    margin: auto;
    z-index: -1;
}

.receipt-footer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* height: 200px; */

}

.receipt-footer .grid-1,
.grid-2,
.grid-3 {
    border: 1px solid black;
    padding: 10px;
}