.about-main-banner-container {
    position: relative;
    /* background-color: #e5f7ff5c; */
    display: flex;
    width: 100%;
    justify-content: center;
    margin: auto;
    padding: 80px;
}

.about-us-div {
    width: 80%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
}

.about-us-container {
    display: grid;
    grid-template-columns: 60% 40%;
    margin-top: 40px;
}

.about-us-container-2 {
    display: grid;
    grid-template-columns: 40% 60%;
}

.about-banner-left {
    width: 55%;
}

.about-banner-right {
    width: 45%;
}

.custom-shape-divider-bottom-1697800910 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1697800910 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 50px;
}

.custom-shape-divider-bottom-1697800910 .shape-fill {
    fill: var(--background);
}

/* founder section start */

.founders-container {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.founders-heading {
    font-family: 'Onest', sans-serif !important;
    text-align: center;
    font-weight: 600;
    font-size: 2rem;
}

.horizontal-rule {
    height: 4px;
    border: 0;
    margin: 0 auto 2px;
    background-color: var(--green);
    width: 4rem;
    border-radius: 20px;
}

.founder-1-div,
.founder-2-div {
    display: none;
    width: fit-content;
    padding: 10px;
    background-color: white;
    border-radius: 20px;
    box-shadow: var(--box-shadow);
}

.founders-list {
    /* display: none; */
    margin: auto;
    margin-top: 2rem;
    gap: 50px;
}

.founder-card {
    background-color: transparent;
    width: 300px;
    height: 300px;
    perspective: 1000px;
}

.founder-card-inner {
    border-radius: 20px;
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 1s;
    transform-style: preserve-3d;
}

.founder-card-front,
.founder-card-back {
    border-radius: 20px;
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.founder-card-front img {
    border-radius: 20px;
    width: 100%;
    height: 100%;
}

.founder-card-front {
    background-color: #bbb;
    color: black;
}

.founder-card-back {
    border: 1px solid lightgray;
    background-color: var(--background);
    color: white;
    transform: rotateX(180deg);
}

.founder-img-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

/* Styles for the founder details container */
.founder-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px 70px;
    gap: 5px;
}

/* Styles for the founder info container */
.founder-info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    overflow: auto;
}

/* Styles for the founder name */
.founder-name {
    font-weight: 600;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
}

/* Styles for the LinkedIn link container */
.linkedin-link {
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    margin-bottom: 0.5rem;
    margin-left: 0.0625rem;
}

/* Styles for the LinkedIn icon */
.linkedin-icon {
    width: 100%;
    height: 100%;
}

/* Styles for the founder role */
.founder-role {
    font-size: 1rem;
    font-weight: 500;
}


/* founder section end*/

/* Styles for the hidden element */
.hidden-element {
    display: none;
    /* Hidden by default */
}

/* Styles for extra-large screens (xl) */
@media (min-width: 1280px) {
    .hidden-element {
        display: block;
        /* Display on extra-large screens */
        width: 5%;
        height: 70%;
        background-color: #FFE3E3;
        /* Background color */
        opacity: 0.8;
        /* Background opacity */
        border-top-left-radius: 1.5rem;
        border-bottom-left-radius: 1.5rem;
        /* Rounded left top corner */
        position: absolute;
        top: 44%;
        right: 0;
    }
}

.about-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin: 80px 0px; */
}

.iner-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    width: 80%;
    margin: 20px auto;
}

@media(width< 576px) {
    .founder-card {
        width: 250px;
        height: 250px;
    }
    .founder-img-size {
        display: none;
    }

    .founder-details {
        padding: 10px;
    }

    .founders-list {
        display: flex;
        flex-direction: column;
    }

    .founder-1-div,
    .founder-2-div {
        display: block;
        padding: 0px;
        margin-bottom: 20px;
    }

    .about-us-container,
    .about-us-container-2 {
        grid-template-columns: repeat(1, 1fr);
    }

    .about-main-banner-container {
        flex-direction: column-reverse;
        gap: 30px;
        padding: 40px;
    }

    .about-banner-left,
    .about-banner-right {
        width: 100%;
    }

    .iner-container {
        grid-template-columns: repeat(1, 1fr);
    }

    .first-vision-div {
        display: flex;
        flex-direction: column-reverse;
    }

    .about-us-container {
        margin-top: 20px;
    }

    .about-us-div {
        grid-template-columns: repeat(1, 1fr);
    }

    .about-main-banner-container {
        flex-direction: column-reverse;
        gap: 30px;
        padding: 40px;
    }
    .about-banner-left,.about-banner-right{
        width: 100%;
    }
    .iner-container {
        grid-template-columns: repeat(1,1fr);
    }
    .first-vision-div{
        display: flex;
        flex-direction: column-reverse;
    }
    .about-us-container{
        margin-top: 20px;
    }
}