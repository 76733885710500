input[type="range"] {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    width: 100%;
    cursor: pointer;
    outline: none;
    /*  slider progress trick  */
    overflow: hidden;
    border-radius: 16px;
}

/* Track: webkit browsers */
input[type="range"]::-webkit-slider-runnable-track {
    height: 10px;
    background: #ccc;
    border-radius: 16px;
}

/* Track: Mozilla Firefox */
input[type="range"]::-moz-range-track {
    height: 10px;
    background: #ccc;
    border-radius: 16px;
}

/* Thumb: webkit */
input[type="range"]::-webkit-slider-thumb {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    height: 10px;
    width: 10px;
    background-color: #fff;
    border-radius: 50%;
    /* border: 0px solid #0098DA; */
    /*  slider progress trick  */
    box-shadow: -407px 0 0 400px #0098DA;
}


/* Thumb: Firefox */
input[type="range"]::-moz-range-thumb {
    height: 10px;
    width: 15px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #f50;
    /*  slider progress trick  */
    box-shadow: -407px 0 0 400px #f50;
}

.calc_container {
    position: relative;
    padding: 30px 18px;
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: space-evenly;
    background-color: white;
    max-width: 80%;
    margin: auto;
    height: fit-content;
    border-radius: 30px;
    box-shadow: var(--box-shadow);
}

.right-calc-sec {
    /* background-image: url(../../assets//graphic/wave.svg);  */
    background-repeat: no-repeat;
    background-position: cover;
    border-radius: 20px;
    padding: 30px 50px;
    height: fit-content;
    width: 450px;
    background-color: #a7ce4520;
}

/* .right-calc-main-div {
    border-radius: 20px;
    background-image: url(../../assets/img/gradiant_bg.png) !important;
    background-size: cover;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    background-position: center;
} */
.calc-left-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 30px;
}

.calc-small-text {
    font-size: 12px;
    font-weight: 700;
    font-family: "Poppins";

}

.input-bottom-span-div {
    display: flex;
    justify-content: space-between;
}

.right-calc-sec img {
    height: 180px;
    width: fit-content;
    margin: auto;
}

.range-value-title {
    margin: 0;
    font-size: 18px;
    font-weight: 700;
}


@media (max-width:576px) {
    .right-calc-sec img {
        display: none;
    }

    .right-calc-sec {
        width: 100%;
        padding: 20px 15px;
    }

    .calc_container {
        display: flex;
        flex-direction: column;
        padding: 20px 20px;
        width: 100%;
        gap: 20px;
    }

    .calc-left-container {
        gap: 0;
    }

}

@media (min-width: 577px) and (max-width: 768px) {
    .right-calc-sec {
        display: flex;
    }

    .right-calc-sec img {
        display: none;
    }

    .calc_container {
        display: flex;
        flex-direction: row;
        padding: 20px 20px;
        width: fit-content;
    }

}