.hero-text-container-main {
    position: absolute;
    width: 100%;
    bottom: -5%;
}

.hero-text-container {
    /* position: absolute; */
    display: flex;
    justify-content: center;
    width: 50%;
    margin: auto;
    text-align: center;
}

.display-font {
    font-size: 90px;
    line-height: 90px;
    font-weight: 900;
}

.hero-section {
    height: 100vh;
}

.hero-section img {
    width: 100%;
    height: 70vh;
    object-fit: cover;
    background-position: bottom;
}

/* quote section start */
.quote-container {
    display: flex;
    position: relative;
    width: 100%;
    height: 40vh;
    
}

.quote-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    /* height: 200px; */
    margin: 50px auto;
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
}



.leftQuoteIcon {
    position: absolute;
    top: 25%;
    left: 10%;
    color: gray;
}

.rightQuoteIcon {
    position: absolute;
    bottom: 30%;
    right: 12%;
    color: gray;
}

/* quote section end */


/* carousel container start */
.carousel-container {
    height: 100vh;
}

.faq-container {
    width: 100%;
    background-color: var(--white);
}

@media (max-width:576px) {
    .quote-container{
        height: fit-content;
    }
    .quote-text{
        font-size: 16px;
    }
    .quote-bg{
        width: 70%;
        margin: 20px auto;
    }
    /* .quote-container{
        padding: 30px 0px 10px 0px;
    } */
    .hero-section {
        height: 50vh;
    }

    .display-font {
        font-size: 42px;
        line-height: 42px;
        font-weight: 900;
    }

    .hero-text-container {
        width: 90%;
    }

    .hero-section img {
        width: 100%;
        height: 40vh;
    }

    .hero-text-container-main {
        position: absolute;
        width: 100%;
        bottom: 5%;
    }
    .leftQuoteIcon{
        left: 5%;
    }
    .rightQuoteIcon{
        right: 5%;
    }

}

@media (min-width: 577px) and (max-width: 768px) {

    .hero-section{
        height: 70vh;
    }
    .display-font {
        font-size: 48px;
        line-height: 48px;
        font-weight: 900;
    }

    .hero-text-container {
        width: 70%;
    }

    .hero-section img {
        width: 100%;
        height: 50vh;
    }

    .hero-text-container-main {
        position: absolute;
        width: 100%;
        bottom: 0%;
    }
    .quote-text{
        font-size: 20px;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .hero-text-container {
        width: 70%;
    }

    .hero-text-container-main {
        bottom: 11%;
    }

    .display-font {
        font-size: 68px;
        line-height: 68px;
    }
}
@media (min-width: 1025px) and (max-width: 1340px) {
    .hero-text-container {
        width: 65%;
    }
}
/* carousel container end */
