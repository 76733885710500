/* onest font */
@import url('https://fonts.googleapis.com/css2?family=Onest:wght@100;200;300;400;500;600;700;800;900&display=swap');
/* lato font */
@import url('https://fonts.googleapis.com/css2?family=Lato&family=Onest:wght@100;200;300;400;500;600;700;800;900&display=swap');
/* popins */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

:root {
  /* --background: white; */
  --background: #FAFAFA;
  --blue: #0098DA;
  --light-blue: #e6f7ff;
  --green: #A8CE45;
  --text-primary: #bebebe;
  --text-para-primary: #808080;
  /* --box-shadow: rgba(0, 0, 0, 0.15) 0px 1.95px 2.6px; */
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

body {
  background-color: var(--background) !important;
  margin: 0;
  /* font-family: 'Lexend Deca', sans-serif; */
  font-family: 'Onest', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.page-wrapper {
  max-width: 1440px;
  margin: auto;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Onest', sans-serif !important;
}

h1 {
  font-size: 3.8rem;
  font-family: 'Onest', sans-serif !important;

}

h2 {
  line-height: 3rem;
  font-weight: 600;
  font-size: 3.4rem;
}

.navbar-brand {
  cursor: pointer;
}

.navigation-font {
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  text-transform: capitalize;
  padding: 0px 20px !important;
  font-size: 18px !important;
  font-family: 'Onest', sans-serif !important;
}

.navigation-font-gray {
  color: var(--text-para-primary) !important;
}

.navigation-font-white {
  color: white
}

.navigation-font-white {
  color: white
}

.navigation-font-small {
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  text-transform: capitalize;
  padding: 5px !important;
  font-size: 14px !important;
  font-family: 'Onest', sans-serif !important;
  color: var(--text-para-primary) !important;
}

.section-title {
  text-transform: capitalize;
  font-size: 42px;
  font-weight: 800;
  letter-spacing: 0.01rem !important;
  padding-top: 0px;
  font-family: 'Onest', sans-serif !important;
}

.detail-page-title {
  text-transform: capitalize;
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 0.01rem !important;
  padding-top: 0px;
  font-family: 'Onest', sans-serif !important;
}

.section-sub-title {
  font-size: 18px;
  margin: 0;
  font-weight: 500;
  letter-spacing: 0.01rem !important;
  color: var(--text-para-primary);
  font-family: 'Onest', sans-serif !important;
}

.showPasswordBtn {
  color: gray;
  border: 0px !important;
  background-color: transparent;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
}

.element-title {
  font-size: 22px;
  margin: 0;
  font-weight: 900 !important;
  text-transform: capitalize;
  font-family: 'Onest', sans-serif !important;
}

.element-title-1 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-transform: capitalize;
  font-family: 'Onest', sans-serif !important;
}

.element-title-2 {
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  text-transform: capitalize;
  font-family: 'Onest', sans-serif !important;
}

.comman-title-light {
  font-size: 42px;
  line-height: 58px;
  margin-bottom: 20px;
  text-transform: capitalize;
  font-family: 'Onest', sans-serif !important;
}

@media (width < 576px) {
  .comman-title-light {
    text-align: center;
    font-size: 30px;
    line-height: 46px;
  }
}

@media (width < 576px) {
  .comman-title-light {
    text-align: center;
    font-size: 30px;
    line-height: 46px;
  }
}

.body-font {
  font-size: 16px !important;
  text-transform: capitalize;
  font-family: "Poppins" !important;
  color: var(--text-para-primary);
}

.small-body-font {
  font-size: 14px !important;
  font-family: "Poppins" !important;
  color: var(--text-para-primary);
}

.copyright-text {
  font-size: 12px;
}

.quote-text {
  font-family: "Poppins";
  font-size: 26px;
  font-style: italic;
  color: var(--text-para-primary);
  font-weight: 500;
}

.quote-text-testominal {
  font-family: "Poppins";
  font-size: 20px;
  font-style: italic;
  color: var(--text-para-primary);
  font-weight: 500;
}

.footer-nav-link-mt {
  margin-top: 20px;
}

.footer-nav-link-mb {
  margin-bottom: 0.75rem;
}

.comman-x-card {
  display: grid;
  margin: auto;
  padding: 40px;
  background-color: white;
  width: 80%;
  border-radius: 30px;
  box-shadow: var(--box-shadow)
}

@media(width < 576px) {
  .comman-x-card {
    padding: 20px;
    width: fit-content;
    margin: 20px;
}

}

/* comman styles end */

.main_container {
  padding-top: 80px;
  position: relative;
  /* height: 90vh; */
  display: flex;
  gap: 80px;
  flex-direction: column;
  /* justify-content: space-around; */
}

@media (width < 576px) {
  .main_container {
    gap: 50px;
  }
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--blue);
  overflow: hidden;
  color: white;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* comman blog section card  start*/
.blog-container {
  width: 100%;
  margin: auto;
}

.blog-card-container {
  width: 100%;
  display: flex;
  gap: 50px;
  flex-wrap: wrap;
  justify-content: center;
}

.blog-card {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  max-width: 395px;
  width: 100%;
  background-color: white;
  box-shadow: var(--box-shadow);
  border-radius: 20px;

}

@media(width < 576px) {
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--blue);
    overflow: hidden;
    color: white;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .blog-card {
    max-width: 300px;
    grid-template-rows: repeat(1, 1fr);
  }

  .element-title-2 {
    font-size: 16px;
    line-height: 22px;
  }
}

.top-img-buzz {
  object-fit: cover;
  height: 288px;
  width: 452px;
  border-radius: 20px;
}

/* comman blog section card  end*/


img {
  user-select: none;
}

.navbar-container {
  padding: 0px 40px;
  height: 100px;
}

/* navbar styles end */

.img-section {
  display: flex;
  width: 100%;
}

.img-size {
  margin: auto;
  width: 420px !important;
}

.founder-img-size {
  margin: auto;
  width: 320px !important;
}

.footer {
  background-color: var(--blue);
  color: white !important;
}

@media (max-width: 576px) {
  .mobile-hide {
    display: none;
  }

  .img-size {
    margin: auto;
    width: fit-content !important;
  }

  .img-size {
    margin: auto;
    width: fit-content !important;
  }

  .element-title {
    font-size: 18px;
  }


  .section-title {
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 0.01rem !important;
  }

  .section-sub-title {
    font-size: 14px;
  }

  .cursor_pointer {
    display: flex;
    cursor: pointer;
  }

  .body-font {
    font-family: "Poppins";
    font-size: 12px !important;
    line-height: 16px !important;
  }

  .quote-text-testominal {
    font-size: 12px;
    margin: 0px 60px;
  }

  .img-section {
    width: 80%;
    margin: auto;
    padding-top: 20px;
  }

  .carousel-container {
    height: 70vh !important;
  }

  .para-text-testominal {
    font-family: "Poppins";
    font-size: 14px !important;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .section-sub-title {
    font-size: 16px;
  }

  .body-font {
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    color: var(--text-para-primary);

  }
}


/* carosuel styles start */


.carousel-icon-background {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: gray;
  border-radius: 50%;
  padding: 20px;
  color: #fff;
}

::-webkit-scrollbar {
  width: 10px;
}

/* carosuel styles end */

.faq-accordian-container {
  width: 75%;
  margin: auto;
}

@media(width < 576px) {
  .faq-accordian-container {
    width: 100%;
  }
}

.accordion {
  width: 90%;
  max-width: 1000px;
  margin: 2rem auto;
}

.accordion-button {
  padding: 20px 25px !important;
  border-radius: 5px !important;
}

.accordion-item {
  background-color: #fff;
  color: #111;
  margin: 1rem 0;
  border-radius: 0.5rem;
  box-shadow: var(--box-shadow);
}

.accordion-item-header {
  padding: 20px;
  min-height: 3.5rem;
  line-height: 1.25rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.accordion-item-header::after {
  content: "\002B";
  font-size: 2rem;
  position: absolute;
  right: 1rem;
}

.accordion-item-header.active::after {
  content: "\2212";
}

.accordion-item-body {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.accordion-item-body-content {
  padding: 1rem;
  line-height: 1.5rem;
  border-top: 1px solid;
  border-image: linear-gradient(to right, transparent, #34495e, transparent) 1;
}

.dropdown-menu {
  width: auto;
}

.avatarDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 38px;
  letter-spacing: 2px;
  font-weight: 700;
  margin: auto;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
  box-shadow: var(--box-shadow);
}

/* swiper */

.swiper {
  width: 80%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically */
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding-bottom: 50px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (width < 576px) {
  .swiper {
    width: 100%;
  }
}

.bg-secondary-soft {
  box-shadow: var(--box-shadow);
  border-radius: 20px;
  padding: 40px 30px;
}

.form-control {
  text-transform: uppercase;
  width: 100%;
  height: 48px;
  font-size: 16px;
  color: gray;
  padding: 20px;
  border: 1px solid #ccc;
  background-color: rgb(243 243 246/1);
  border-radius: 8px;
}

.form-card {
  border-radius: 20px;
  box-shadow: var(--box-shadow);
}

.auth-form-control {
  width: 100%;
  height: 48px;
  font-size: 16px;
  color: gray;
  padding: 20px;
  border: 1px solid #ccc;
  background-color: rgb(243 243 246/1);
  border-radius: 8px;
}

.error-message {
  font-size: 14px;
  margin-top: 5px;
  color: red;
}

.term-page-container {
  position: relative;
  padding: 50px;
  width: 100%;
  /* margin: auto; */
}

/* MARKET BUZZ */

/* plan ststus btn */


.checkbox-wrapper-5 .check {

  --size: 25px;
  position: relative;
  background: linear-gradient(90deg, #6effa6, #04bc35);
  line-height: 0;
  perspective: 200px;
  font-size: var(--size);
}

.checkbox-wrapper-5 .check input[type="checkbox"],
.checkbox-wrapper-5 .check label,
.checkbox-wrapper-5 .check label::before,
.checkbox-wrapper-5 .check label::after,
.checkbox-wrapper-5 .check {
  appearance: none;
  display: inline-block;
  border-radius: var(--size);
  border: 0;
  transition: .35s ease-in-out;
  box-sizing: border-box;
  cursor: pointer;
}

.checkbox-wrapper-5 .check label {
  width: calc(2.2 * var(--size));
  height: var(--size);
  background: #d7d7d7;
  overflow: hidden;
}

.checkbox-wrapper-5 .check input[type="checkbox"] {
  position: absolute;
  z-index: 1;
  width: calc(.8 * var(--size));
  height: calc(.8 * var(--size));
  top: calc(.1 * var(--size));
  left: calc(.1 * var(--size));
  background: linear-gradient(45deg, #dedede, #ffffff);
  box-shadow: 0 6px 7px rgba(0, 0, 0, 0.3);
  outline: none;
  margin: 0;
}

.checkbox-wrapper-5 .check input[type="checkbox"]:checked {
  left: calc(1.3 * var(--size));
}

.checkbox-wrapper-5 .check input[type="checkbox"]:checked+label {
  background: transparent;
}

.checkbox-wrapper-5 .check label::before,
.checkbox-wrapper-5 .check label::after {
  content: "· ·";
  position: absolute;
  overflow: hidden;
  left: calc(.15 * var(--size));
  top: calc(.5 * var(--size));
  height: var(--size);
  letter-spacing: calc(-0.04 * var(--size));
  color: #9b9b9b;
  font-family: "Times New Roman", serif;
  z-index: 2;
  font-size: calc(.6 * var(--size));
  border-radius: 0;
  transform-origin: 0 0 calc(-0.5 * var(--size));
  backface-visibility: hidden;
}

.checkbox-wrapper-5 .check label::after {
  content: "●";
  top: calc(.65 * var(--size));
  left: calc(.2 * var(--size));
  height: calc(.1 * var(--size));
  width: calc(.35 * var(--size));
  font-size: calc(.2 * var(--size));
  transform-origin: 0 0 calc(-0.4 * var(--size));
}

.checkbox-wrapper-5 .check input[type="checkbox"]:checked+label::before,
.checkbox-wrapper-5 .check input[type="checkbox"]:checked+label::after {
  left: calc(1.55 * var(--size));
  top: calc(.4 * var(--size));
  line-height: calc(.1 * var(--size));
  transform: rotateY(360deg);
}

.checkbox-wrapper-5 .check input[type="checkbox"]:checked+label::after {
  height: calc(.16 * var(--size));
  top: calc(.55 * var(--size));
  left: calc(1.6 * var(--size));
  font-size: calc(.6 * var(--size));
  line-height: 0;
}