.graphic_hero_bg,
.custom_hero_bg {
    z-index: -1;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 40rem;
}


.plan_card_container {
    position: relative;
    /* background-color: white; */
    display: flex;
    justify-content: center;
    padding: 80px 20px;
}
@media (width < 576px) {
    .plan_card_container{
        display: flex;
        flex-direction: column;
    padding: 20px 20px;
    flex-direction: column;
    align-items: center;

    }
}

@media (width < 576px) {
    .plan_card_container {
        display: flex;
        flex-direction: column;
        padding: 20px 20px;
        flex-direction: column;
        align-items: center;

    }
}

.stem-tree {
    margin-top: 50px;
    width: 360px;
    height: 350px;
    background-repeat: no-repeat;
    background-image: url(../../assets/graphic/bg-graphic/stem-tree-large.svg);
}

@media (width < 576px) {
    .stem-tree {
        height: auto;
    }

    .plan_card_container {
        display: flex;
        flex-direction: column;
        padding: 20px 20px;
        flex-direction: column;
        align-items: center;

    }
}



.plan_card {
    display: grid;
    grid-template-rows: 45% 55%;
    padding: 0px 18px;
    background-color: white;
    width: 350px;
    height: 450px;
    border-radius: 30px;
    margin: 10px;
    box-shadow: var(--box-shadow)
}
@media (width < 576px) {
    .plan_card{
        max-width: 300px;
        width: 100%;
    }
}

@media (width < 576px) {
    .plan_card {
        max-width: 300px;
        width: 100%;
    }
}



.plan_img_div {
    display: flex;
    justify-content: center;
}

.plan_img_div img {
    width: auto;
    margin: auto;
}

.plan_text_container {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: start;
    gap: 12px;
    /* margin-bottom: 25px; */
}

@media (width < 576px) {
    .plan_text_container {
        gap: 5px;
    }
}

.plan_no_1 {
    padding: 6px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D7D7D7;
    width: 40px;
    height: 40px;
    font-family: "Poppins";
    font-weight: 500;
    border-radius: 15px;
    margin: 0px auto;
}

.plan_no_2 {
    padding: 6px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: gold;
    width: 40px;
    height: 40px;
    font-family: "Poppins";
    font-weight: 500;
    border-radius: 15px;
    margin: 0px auto;
}

.plan_no_3 {
    padding: 6px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4FB3DBff;
    width: 40px;
    height: 40px;
    font-family: "Poppins";
    font-weight: 500;
    border-radius: 15px;
    margin: 0px auto;
}



.sub_plan_title {
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    text-transform: capitalize;
}

.plan-title-text {
    font-size: 48px;
    line-height: 68px;
    color: #0098DA;
}
@media (width < 576px) {
    .plan-title-text {
        font-size: 36px;
     line-height: 40px;
    }
}

@media (width < 576px) {
    .plan-title-text {
        font-size: 36px;
        line-height: 40px;
    }
}



.graphic_coin {
    position: absolute;
    top: 0;
    right: 5%;
    transform: skewX(30deg);

}
@media (width < 576px) {
    .graphic_coin{
        display: none;
    }
}

@media (width < 576px) {
    .graphic_coin {
        display: none;
    }
}

.graphic_lines {
    position: absolute;
    top: -5%;
    left: 5%;
    transform: skewX(15deg);
}
@media (width < 576px) {
    .graphic_lines{
        display: none;
    }
}

@media (width < 576px) {
    .graphic_lines {
        display: none;
    }
}

.graphic_coin img {
    width: 80px;
}

.graphic_lines img {
    width: 150px;
}

.rate-div,
.tenure-div {
    display: flex;
    flex-direction: column;
    background: white;
    width: fit-content;
    padding: 10px 15px;
    border-radius: 10px;
    box-shadow: var(--box-shadow)
}

.rate-div {
    position: absolute;
    top: 20%;
    left: 12%;
}

.tenure-div {
    position: absolute;
    bottom: 30%;
    right: 9%;
}

@media (width < 576px) {
    .rate-div {
        position: relative;
        top: 0%;
        left: 0%;
    }

    .tenure-div {
        position: relative;
        bottom: 0%;
        right: 0%;
    }
}