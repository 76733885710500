.section-padding {
    padding: 100px 0px 80px 0px;
}

.section-margin-top {
    margin-top: 50px !important;
}

.section-padding-top {
    padding-top: 50px !important;
}

.section-padding-bottom {
    padding-bottom: 50px !important;
}
.mt-40{
    margin-top: 40px; 
}
.mt-20{
    margin-top: 20px; 
}
.mb-26{
   margin-bottom: 26px; 
}
.mb-20{
   margin-bottom: 20px; 
}

@media(max-width:576px){
    .section-padding{
        padding: 50px 0px 30px 0px;
    }
    .w-sm-90{
        width: 90% !important;
    }
}